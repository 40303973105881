<template>
<div>
  <rxNavBar title="温馨提示" androidOrIOSFlag="true"></rxNavBar>

  <div class="receiptText">
    请联系签约管家告知开票需求
    <div>或致电海米公寓客服：400-0411-021</div>
    <text>\n</text>
    <div class="info">发票名称：与签约合同主体保持一致，不得随意变更发票名称</div>

  </div>

  <div bindtap="phoneCall" class="bottomCall" @click="showMobile">
    <img src="../../../assets/images/tel.png" alt="" ><div>联系管家</div>
  </div>

  <!--拨打电话弹窗-->
  <dial-mobile
      :ownerMobile="temporaryOwnerMobile"
      :userName="temporaryOwnerName"
      :dialogTitle="dialogTitle"
      :mobileShow="isMobileShow"
      @hideDialog="hideDialog"
  >
  </dial-mobile>
</div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {List, NavBar, PullRefresh} from "vant";
import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
import {
  checkAndroid, checkIOS, getStaffId,
  globaluserId,
  money, openInWebview,
  responseUtil
} from "../../../libs/rongxunUtil";
import {
  browseStaffList,
  getFindPhone
} from "../../../getData/getData";
export default {
  name: "receipt",
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    dialMobile,
    rxNavBar,


  },
  data(){
    return{
      isMobileShow:false,
      temporaryOwnerMobile:'',
      temporaryOwnerName:'',
      dialogTitle:'',
    }

  },
  mounted() {
    //localStorage.clear()
    this.getFindPhone()
  },
  methods:{
    getFindPhone(){

      let that=this
      let initData={}
      initData.globaluserId = globaluserId()
      getFindPhone(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          this.temporaryOwnerMobile = response.data.data.phone.phoneNumber
          this.temporaryOwnerName = response.data.data.phone.staffName
        })
      })
    },
    //返回上一页
    leftReturn() {
      this.$router.go(-1)
    },
    //关闭拨打电话弹窗
    hideDialog() {
      let that = this
      that.isMobileShow = false
    },
    showMobile() {
      let that=this
      let initData={}
      initData.globaluserId = globaluserId()
      getFindPhone(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          that.temporaryOwnerMobile = response.data.data.phone.phoneNumber
          that.temporaryOwnerName = response.data.data.phone.staffName
        })
      })
      that.isMobileShow = !that.isMobileShow
    },
  }

}
</script>

<style scoped>
.receiptText{
  width: auto;
  height: 350px;
  z-index: 2;
  padding: 0px 25px;
  font-size: 17px;
  /* padding-bottom: 10rpx; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;

}
.bottomCall{
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #ebebeb;
  justify-content:flex-end;
  box-sizing: border-box;
  padding-right: 25px;
  font-size: 20px;
}
.bottomCall img{
  width: 25px;
  height: 25px;
  margin-right: 13px;
}
</style>
